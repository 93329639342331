import { render, staticRenderFns } from "./ProjectManagement.vue?vue&type=template&id=e1a65b82&scoped=true&"
import script from "./ProjectManagement.vue?vue&type=script&lang=js&"
export * from "./ProjectManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1a65b82",
  null
  
)

export default component.exports