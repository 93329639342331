<template>
  <div class="project-card">
    <div class="left">
      <div class="project-image fd-w-1/3">
        <img :src="getThumbnail" alt="thumbnail">
      </div>
      <div class="project-description fd-w-2/3">
        <div class="fd-w-full fd-inline-flex fd-items-center fd-justify-between">
          <h1>{{ item.name }}</h1>
          <Actions
            class="fd--mr-4"
            :verticalIcon="false"
            :hasEditIcon="false"
            :hasViewIcon="false"
            @showRemoveComponent="toggleConfirmDialogue"
          />
        </div>
        <div class="address fd-inline-flex fd-items-center">
          <SvgLoader name="markerIcon" />
          <p>{{ item.address ? item.address : $t('projectManagement.address') }}</p>
        </div>

        <p class="description">{{ item.description ? item.description : $t('projectManagement.descriptionHere') }}</p>

        <div class="fd-flex fd-items-center">
          <div class="fd-flex fd-items-center fd-mr-10">
            <SvgLoader name="phasesIcon" />
            <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-semibold">{{ item.phase_count ? item.phase_count : '0' }} {{ $t('projectManagement.phases') }}</span>
          </div>
          <div class="fd-flex fd-items-center fd-mr-10">
            <SvgLoader name="complexesIcon" />
            <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-semibold">{{ item.complexes_count ? item.complexes_count : '0' }} {{ $t('projectManagement.complexes') }}</span>
          </div>
          <div class="fd-flex fd-items-center fd-mr-10">
            <SvgLoader name="propertyIcon" />
            <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-semibold">{{ item.properties_count ? item.properties_count : '0' }} {{ $t('projectManagement.property') }}</span>
          </div>
        </div>

        <div class="fd-w-full fd-inline-flex fd-items-center fd-justify-between" style="margin-top: 20px">
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-0">
              <SvgLoader name="soldIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_sold ? item.total_sold : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalSold') }}</span>
            </div>
          </div>
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-1">
              <SvgLoader name="reservedIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_reserved ? item.total_reserved : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalReserved') }}</span>
            </div>
          </div>
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-2">
              <SvgLoader name="availableIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_available ? item.total_available : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalAvailable') }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="right">
      <div class="fd-w-2/3 fd-border-l fd-ml-7">
        <div class="avatars fd-w-full fd-h-full fd-flex fd-items-center fd-justify-center">
          <div>
            <div
              v-for="(agent, index) in item.connected_agents_avatars"
              v-if="index <= 7"
              :key="index"
              class="avatar"
            >
          <span
            v-if="index === 7 || index === (item.connected_agents_avatars.length - 1)"
            class="fd-absolute fd-left-0 fd-top-0 fd-w-full fd-h-full fd-flex fd-items-center fd-justify-center fd-text-sm fd-text-theme-2"
            style="background-color: rgba(255,255,255, .77)"
          >
            {{ getAgentsCount }}
          </span>
              <img :src="agent === 1 ? agent : defaultImage" alt="profile image">
            </div>
          </div>
        </div>
      </div>
      <div
        class="fd-w-1/3 fd-flex fd-items-center fd-justify-center fd-h-full fd-bg-theme-8 fd-cursor-pointer hover:fd-opacity-80"
        style="border-color: #EBEEEC"
        @click="goTo"
      >
        <div>
          <feather-icon class="fd-text-theme-4" size="25" icon="ChevronRightIcon" />
        </div>
      </div>
    </div>
    <ConfirmDialogue :id="item.id" @remove="removeProject" />
  </div>
</template>

<script>
import Actions from "@/views/components/global/Actions";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import toast from "@/mixins/toast";
export default {
  name: "Project",
  mixins: [toast],
  components: {ConfirmDialogue, Actions},
  data() {
    return {
      rsTop: 0,
      agentTop: 0,
      showActions: false,
      defaultImage: null
    }
  },
  created() {
    this.defaultImage = require('@/assets/images/pages/project/user-default.jpeg')
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getThumbnail() {
      return this.item.thumbnail ? this.item.thumbnail : require('@/assets/images/pages/project/default-image.png')
    },
    getAgentsCount() {
      if ('connected_agent_count' in this.item && this.item.connected_agent_count > 0) {
        if (this.item.connected_agent_count === 1) {
          return 1
        }
        return this.item.connected_agent_count + '+'
      }
      return 0
    }
  },
  methods: {
    goTo() {
      return this.$router.push({ name: 'projects.show', params: { id: this.item.id }})
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", `confirmDialogue-${this.item.id}`);
    },
    removeProject() {
      this.$store.dispatch('project/deleteProject', this.item.id)
        .then(() => {
          this.makeToast(this.$t('toastMessages.removed'), this.$t('global.removed'))
        })
        .catch(() => {
          this.makeToast(this.$t('toastMessages.problem'), this.$t('global.problem'), '', 'danger')
        })
        .finally(() => {
          this.toggleConfirmDialogue()
          this.$emit('updateList')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-card {
  width: 100%;
  height: 265px;
  display: flex;
  align-items: stretch;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 0;
  margin-bottom: 20px;
  overflow: hidden;

  & .left {
    width: 87%;
    height: 265px;
    display: flex;
    align-items: stretch;
    padding: 18px 0 18px 18px;
  }

  & .right {
    width: 13%;
    height: 265px;
    display: flex;
    align-items: stretch;
  }

  @media screen and (min-width: 1575px) {
    & .left {
      width: 86%;
    }
    & .right {
      width: 14%;
    }
  }
  @media screen and (min-width: 1653px) {
    & .left {
      width: 88%;
    }
    & .right {
      width: 12%;
    }
  }

  & div.project-image {
    & img {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & div.project-description {
    margin-left: 16px;

    & h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #202A21;
      margin-bottom: 7px !important;
    }

    & div.address {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #838883;
        margin-left: 10.33px;
        margin-bottom: 0 !important;
      }
      margin-bottom: 14px !important;
    }
    & p.description {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #838883;
      margin-bottom: 28px !important;
    }
  }
}
.avatar {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 34px;
  border: 2px solid #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);
  margin-right: auto;
  margin-left: auto;
}
.avatar:not(:first-child) {
  margin-top: -15px;
}

.avatar img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.profile--image {
  width: 32px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);
  border: 2px solid #ffffff;

  img {
    width: 32px;
    height: 30px;
    object-fit: cover;
  }
}
.statistic {
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &-0 {
    background-color: #E5F8EE;
  }
  &-1 {
    background-color: #FFF3E8;
  }
  &-2 {
    background-color: #EEEDFD;
  }

}
</style>