<template>
  <div>
    <div v-if="$route.name !== 'projects'">
      <router-view></router-view>
    </div>
    <div v-else>
      <div class="fd-w-full fd-flex fd-items-center fd-justify-between">
        <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('projectManagement.listOfProject') }}</span>
        <b-button variant="primary" @click="createNewProject" size="sm">
          <span class="fd-text-lg  fd-mr-2 fd-font-thin">+</span>
          <span>{{ $t('projectManagement.addProject') }}</span>
        </b-button>
      </div>

      <div v-if="loading" class="fd-w-full fd-flex fd-items-center fd-justify-center">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      <div v-if="!loading" class="fd-mt-6 fd-pb-20">
        <div
          v-for="project in projects"
          :key="project.id"
        >
          <Project :item="project" @updateList="getData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Project from "@/views/components/ProjectManagement/Project";
import { BSpinner, BButton } from 'bootstrap-vue'

import { mapGetters } from 'vuex'
import toast from "@/mixins/toast";
export default {
  name: "ProjectManagement",
  components: { Project, BSpinner, BButton },
  mixins: [toast],
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.getData()
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name === 'projects') {
          this.getData()
          this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      projects: 'project/projects'
    }),
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('project/getProjects').finally(() => {
        this.loading = false
      })
    },
    createNewProject() {
      this.$store.dispatch('project/createNewProject', {
        ana_id: this.$store.getters['user/user'].id
      }).then(response => {
        this.makeToast(response.data.message)
        this.loading = true
        this.$store.dispatch('project/getProjects').finally(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>